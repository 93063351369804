import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { AppService } from './app.service';
import { DataService } from './data.service';
import { TouchID } from '@ionic-native/touch-id/ngx';
import { Events } from './events.service';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';

const TOKEN_KEY = 'petro.appt.session';
const ENCODE_KEY = "7061737323313233";
const ENCODE_IV = "7061737323313233";


@Injectable({
  providedIn: 'root'
})
export class AuthService {


  authenticationState = new BehaviorSubject(false);  // Indica si hay un usuario autenticado o no

  // Datos del usuario actual
  private _currentSession: any;
  private _lastSession:any = null;
  get currentSession(): any {
    return this._currentSession;  
  }
  get lastSession(): any {
    return this._lastSession;  
  }

  constructor(
    private storage: Storage, 
    private plt: Platform,
    private events: Events,
    private data: DataService,
    private appCtx: AppService,
    private touchid: TouchID,
    private router: Router
  ) { 
  }
 
  // checkToken()
  // Verifica si hay usuario authenticado o no
  checkToken():Promise<any> {
    return new Promise((resolve,reject) => {
      this.storage
          .get(TOKEN_KEY)
          .then(
            res => resolve(res),
            err => resolve(null)
          );
    });
  };


 

  // login()
  // Intentar un inicio de sesion con las credenciales indicadas
  //
  login(loginid, pwd) {
    return new Promise((resolve, reject) => {

      this.data
          .login(loginid, this.encodePwd(pwd))
          .then((resp: any) => {
            resp.lastcheck = new Date();
            this.storage
                .set(TOKEN_KEY, resp)
                .then(() => {
                  this._currentSession = resp;
                  this.appCtx.user = resp;
                  console.log(resp);
                  this.authenticationState.next(true);
                });              

            this.events.publish("app-auth-logon", resp);    
            resolve(resp);
          })
          .catch(err => reject(err));
    });
  } // login



  // logout()
  // Finaliza la sesion actual
  // 
  logout() {
    if (this._currentSession)
      this._lastSession = {
        cstEmail: this._currentSession.cstEmail,
        cstName: this._currentSession.cstName,
        cstLastname: this._currentSession.cstLastname
      };
          
    return new Promise(async (resolve, reject) => {
      try {
        await this.data.logout();
      } catch (err) {        
        console.log(err);
      }
      await this.storage.remove(TOKEN_KEY);
      this._currentSession = null;
      this.appCtx.user = null;
      this.events.publish("app-auth-logout");    
      this.authenticationState.next(false);
      resolve()      
    });
  } // logout


  /**
   * @method resume
   * Continuar con una sesion previa
   * @param  {any}  session Datos de la sesion previa
   */
  async resume(session?:any) {    
    // Si se indicaron los datos de la session, se reanuda
    // la sesion indicada.
    if (session) {
      this._currentSession = session;
      this.appCtx.user = session;
      session.lastcheck = new Date();
      this.storage.set(TOKEN_KEY, session);
      this.authenticationState.next(true);              
      this.events.publish("app-auth-logon", session); 
      return;
    }


    // Verificamos si hay una sesion previa
    const now = new Date();        
    let sess = null;
    try {
      sess = await this.storage.get(TOKEN_KEY);
    } catch (err) {
    }
    if (!sess) {
      this.authenticationState.next(false); 
      return;
    }

    // Verificamos el tiempo (en segs) transcurridos desde
    // la ultima sesion. Si es menor a 60 seg, se retoma 
    // la sesion directamente (solo para device)
    let lastcheck = now;
    if (sess.lastcheck) lastcheck = new Date(sess.lastcheck);
    const secs = (now.getTime() - lastcheck.getTime()) / 1000;  
    if (secs < 60 || !this.appCtx.capabilities.biometrics) {
      this.resume(sess);
      return;
    }  


    // Pedimos autenticar por biometrica
    const resume = await this.verifyIdentity();


    if (resume) {
      this.resume(sess);
    } else {
      this.authenticationState.next(false); 
    }

   
  } // resume
 

  verifyIdentity():Promise<any> {
    return new Promise((resolve, reject) => {
      this.touchid
          .verifyFingerprint('Sucursal Virtual')
          .then(
            res => resolve(true), 
            err => resolve(false)
          );
    });
  } // verifyIdentity



  isAuthenticated() {
    return this.authenticationState.value;
  }


  // encodePwd()
  // Codificar el pwd con SHA256 antes de enviarlo 
  // al webservice
  //
  private encodePwd(input:string): string {
    return input;
    let key = CryptoJS.enc.Utf8.parse(ENCODE_KEY);
    let iv = CryptoJS.enc.Utf8.parse(ENCODE_IV);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(input), key,
        {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });    
    return encrypted.toString();
  }

} // AuthenticationService
