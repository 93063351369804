import { Component, Inject, ViewChildren, QueryList } from '@angular/core';
import { APP_BASE_HREF } from "@angular/common";
import { Subject, BehaviorSubject } from 'rxjs';
import { Platform, ModalController, ActionSheetController, PopoverController, IonRouterOutlet, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { AppService } from './services/app.service';
import { DataService } from './services/data.service';
import { AuthService } from './services/auth.service';
import { UIService } from './services/ui.service';
import { LogService } from './services/log.service';
import { Events } from './services/events.service';

import { environment } from '../environments/environment';

const APP_NOTIFICATION_MONITOR_INTERVAL = 60 * 2 * 1000;
const APP_VEHICLES_MONITOR_INTERVAL = 60 * 2 * 1000;


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  notificationsMonitor = new BehaviorSubject(null);
  vehiclesMonitor = new BehaviorSubject(null);
  hwBackButton = new Subject();
  devMode:boolean = false;

  versionInfo:any;

  private _buildClicks:number = 0;

  get isLogged():boolean {
    return (this.auth.isAuthenticated());
  }

  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;

  constructor(
    @Inject(APP_BASE_HREF) public baseHref: string,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private menu: MenuController,
    private router: Router,
    private route: ActivatedRoute,    
    private data: DataService,
    private appCtx: AppService,
    private auth: AuthService,
    private ui: UIService,
    private oneSignal: OneSignal,
    private log: LogService,
    private localNtf: LocalNotifications,
    private events: Events,

    public modalCtrl: ModalController,
    private actionSheetCtrl: ActionSheetController,
    private popoverCtrl: PopoverController
  ) {
    this.versionInfo = this.appCtx.versionInfo;
    this.initializeApp();
    //console.log(this.baseHref);
  }

  initializeApp() {

    let start = () => {
          this.requestNotifications(true);
          this.requestVehicles();
          //this.startNotificationsMonitor();
          this.startVehiclesMonitor();          
          this.router.navigate(['priv','home']);
    };

    this.platform.ready().then(() => {
      console.clear();     
      console.log("[IPL]","Starting");
      console.log("[appInfo] Version:", this.versionInfo.version, "Build:",this.versionInfo.build, "Date:", this.versionInfo.fecha); 
      this.statusBar.styleDefault();
      this.backLoad();
      this.setupPushNotifications();
      this.splashScreen.hide();
      this.auth.authenticationState.subscribe((state) => {
        if (state) start();
      }); // authenticationState.subscribe     
      this.events.subscribe("app-auth-logout", () => {
        this.router.navigate(["login"]);
      });   

      if (!this.appCtx.isDevice && !environment.webBookingMode)
        this.auth.resume();      
      
    }); // Platform-Ready

    this.platform.resume.subscribe(() => {
        this.auth.resume();
    }); // Platform-resume
  } // initializeApp


  backButtonEvent() {
    this.hwBackButton.next(null);
  } // backButtonEvent


  about() {
    this.menu.toggle();
    this.router.navigate(["about"]);
  }

  accountInfo() {
    this.menu.toggle();
    this.router.navigate(["priv","user-profile"]);
  }

  consola() {
    this.menu.toggle();
    this.router.navigate(["console"]);
  }

  async salir() {
    this.menu.toggle();
    await this.auth.logout();
    this.router.navigate(["login"]);
  }

  onBuildClick() {
    this._buildClicks++;
    if (this._buildClicks == 5 && !this.devMode)
      this.devMode = true;
  }

  backLoad() {
    this.data
        .getBranches()
        .then(branches => {
          this.appCtx.branches = branches;
          console.log("[backLoad]",branches);
        })
        .catch(err => console.log("[backLoad]",err));
  } // backLoad



  startNotificationsMonitor() {
    setInterval(() => {
      this.requestNotifications();      
    }, APP_NOTIFICATION_MONITOR_INTERVAL)
  }

  startVehiclesMonitor() {
    setInterval(() => {
      this.requestVehicles();      
    }, APP_VEHICLES_MONITOR_INTERVAL)
  }


  requestNotifications(from_server?:boolean) {
    console.log("[requestNotifications]",from_server || false);
    return new Promise(async (resolve,reject) => {


      let saveRows = (rows) => {
        return new Promise((resolve, reject) => {
          let data = rows.map(i => {
            i.rowid = i.ntfId;
            return i;
          });
          this.data.dbSet("notifications",data);
          resolve(data);
        });
      }

      let finish = (rows) => {
        return new Promise((resolve, reject) => {
          this.notificationsMonitor.next(rows);
          resolve(rows);
        });
      }

      if (from_server) {
        this.data
            .getNotifications()
            .then(saveRows)
            .then(finish)
            .then(rows => resolve(rows))
            .catch(err => reject(err));

      } else {
        this.data
            .dbGet("notifications")
            .then(finish)
            .then(rows => resolve(rows))
            .catch(err => reject(err));
      }

    });
  }

  requestVehicles() {
    return new Promise((resolve,reject) => {
      console.log("[requesting vehicles]");

      if (!this.isLogged) {
        console.log("[requesting vehicles] not logged");
        resolve(null);
        return;
      }
      
      this.data
          .getVehicles()
          .then(res => {
            console.log("[requesting vehicles]",res);
            this.vehiclesMonitor.next(res);
            resolve(res);
          })
          .catch(err => reject(err));    
    });
  }

  setVehicles(vehicles) {
    this.vehiclesMonitor.next(vehicles);
  }

  startAppointment() {
    this.router.navigate(["priv","appointment"]);
  } // startAppointment

  
   async setupPushNotifications() {
    this.oneSignal.startInit(environment.ONESIGNAL_APPID, environment.ONESIGNAL_SENDERID);
    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
 
    // A notification was received
    this.oneSignal.handleNotificationReceived().subscribe(data => {
      try {
        let msg = data.payload.body;
        let title = data.payload.title;
        let additionalData = data.payload.additionalData;
        let payload = null;
        if (additionalData && additionalData.payload) {
          let notif = JSON.parse(additionalData.payload);
          const isAndroid = this.platform.is("android");
          this.data
              .dbAdd("notifications",notif)
              .then(rows => {
                this.notificationsMonitor.next(rows);
                this.localNtf.schedule({
                  id: 1,
                  title: title,
                  text: msg,
                  sound: isAndroid? 'file://sound.mp3': 'file://beep.caf',
                  data: null
                });
              })
              .catch(err => this.log.error(err));
          
        }      

      } catch (err) {
        this.log.add("handleNotificationReceived",err,1);
      }
    });
 
    // Notification was really clicked/opened
    this.oneSignal.handleNotificationOpened().subscribe(data => {
      try {
        // Just a note that the data is a different place here!
        //let additionalData = data.notification.payload.additionalData;
        let additionalData = data.notification.payload.additionalData;
        if (additionalData && additionalData.payload) {
          let notif = JSON.parse(additionalData.payload);
          this.data 
              .dbGet("notifications")
              .then(rows => {
                let n = rows.filter(i => i.ntfId = notif.ntfId);
                if (n && n.length > 0) {
                  let navigationExtras: NavigationExtras = {
                    state: { notif: n[0] }
                  };      
                  this.router.navigate(["priv","notifications"], navigationExtras);                            
                } else {
                  alert("No se encontro la notificacion #" + notif.ntfId);
                }
              });
        }  

      } catch (err) {
        alert(err);
      }
    });

    this.oneSignal.getIds().then(identity => {
      this.appCtx.oneSignalPlayerID = identity.userId;
    });

    this.oneSignal.endInit();
  } // setupPushNotifications

}
