import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AppService } from '../services/app.service';
import { Events } from './events.service';

const APP_STORE_KEY = "com.petroautos.sucursalvirtual.log";

@Injectable({
  providedIn: 'root'
})
export class LogService {

  entries:any[];

  constructor(
  	private store: Storage,
  	private platform: Platform,
  	private events: Events,
    private http: HttpClient,
    private appCtx: AppService
  ) { 
  	this.reload();
  }


  add(sender:string, message:string, level?:number, data?:any) {
    if (typeof message == "object") 
      message = JSON.stringify(message);
  	let entry = {
  		posted: new Date(),
      stamp: null,
  		level: level || 5,
  		sender: sender,
  		message: message,
  		data: (data) ? JSON.stringify(data) : ""
  	}   
    entry.stamp = entry.posted.toDateString() + " " + entry.posted.toLocaleTimeString();

    if (typeof entry.message == "object") {
      try {
        entry.message = JSON.stringify(entry.message);
      } catch (err) {
        entry.message = "Error al convertir un objeto err: " + err;
      }
    }

    if (entry.message == "cordova_not_available") return;

    if (!this.entries) this.entries= [];
  	this.entries.push(entry);
  	this.store
  	    .set(APP_STORE_KEY, this.entries);

    if (!this.platform.is("cordova"))
    	console.log("[" + sender + "]",message,data);

    this.events.publish("app-log-updated", entry);

    try {
      let url = environment.apiUrl + "/sqlsp/run/spLogError/in/ctapp";
      let platform = "pwa";
      if (this.platform.is("android")) {
        platform = "android";
      } else if (this.platform.is("ios")) {
        platform = "ios";
      } 
      
      let info = {
        platform: platform,
        cstEmail: (this.appCtx.user) ? this.appCtx.user.cstEmail.trim() : "(not logged)",
        playerID: this.appCtx.oneSignalPlayerID || "",
        sender: entry.sender,
        data: entry.data
      }

      let payload = {
        sender: "com.petroautos.sucursalvirtual",
        errormsg: entry.message,
        errorinfo: JSON.stringify(info)
      }
      this.http
          .put<any[]>(url, payload)
          .subscribe(
            resp => console.log("error posted"),
            err => console.log(err)
          );        

    } catch (err) { 
      console.log(err);
    }
  }

  info(message:string, data?:any) {
  	this.add("INFO",message,5,data);
  }

  error(message:string, data?:any) {
  	this.add("ERROR",message,1,data);
  }

  warning(message:string, data?:any) {
  	this.add("WARNING",message,3,data);
  }

  reload() {
  	this.store
  	    .get(APP_STORE_KEY)
  	    .then(rows => this.entries = rows || [])
  	    .catch(err => {
  	    	console.log(err);
  	    	this.entries = [];
  	    });  	
  }


  list(level?:number, sender?:string) {
  	level = level || 5;
  	sender = sender || "(all)";

  	let entries = [];
  	for (let i = this.entries.length - 1; i >= 0; i--) {
  		let entry = this.entries[i];
  		if ((sender == "(all)" || entry.sender == sender) && entry.level <= level)
  			entries.push(entry);
  	}

  	return entries;
  }


  clear() {
  	this.entries = [];
  	this.warning("El log ha sido reiniciado");
  }

}
