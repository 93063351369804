import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { wbAuthGuard } from './wb-auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: './public/login/login.module#LoginPageModule' },
  { path: 'custregister', loadChildren: './public/custregister/custregister.module#CustregisterPageModule' },
  {
    path: 'priv', 
    canActivate: [AuthGuard],
    loadChildren: './priv/private-routing.module#PrivateRoutingModule'
  },
  {
    path: 'wb',
    canActivate: [wbAuthGuard],
    loadChildren: './wb/wb-routing.module#WebBookingRoutingModule'
  },
  { path: 'console', loadChildren: './public/console/console.module#ConsolePageModule' },
  { path: 'about', loadChildren: './public/about/about.module#AboutPageModule' },
  { path: 'book/:phone', loadChildren: './public/login/login.module#LoginPageModule' },
  { path: 'book/update/:phone', loadChildren: './public/login/login.module#LoginPageModule' },
  { path: 'done', loadChildren: './wb/done/done.module#DonePageModule' },
  { path: 'error', loadChildren: './wb/error/error.module#ErrorPageModule' }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
