import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }
}


/**
 * DateUtils (class)
 * Utilidades relacionadas con manejo de fechas
 *
 * @author	Victor Espina S
 * @date 	Ago 25, 2019
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class DateUtils {


	/**
	 * @method date
	 * Devuelve un valor tipo date a partir de un valor dado. El valor indicado puede ser
	 * un valor tipo date, number o string.  Si es un string, puede ser una fecha valida
	 * o valores especiales como: now, today, tomorrow, yesterday.
	 * 	
	 * @param  {any}  value         Valor a convertir en date.
	 * @param  {any}  fallback      Valor a utilizar si la fecha no es valida
	 * @return {date}               Valor tipo date correspondiente al valor indicado
	 */
	date(value:any, fallback?:any):Date {
		let dateValue = null;
		const now = new Date();
		if (value && typeof value == "object" && value.constructor == Date) return value;

		try {
			if (!value && fallback) {
				dateValue = this.date(fallback);

			} else if (typeof value == "string") {
				switch (value.toLowerCase()) {
					case "now":
					    dateValue = now;
						break;

					case "today":
					    dateValue = new Date(now.getFullYear(), now.getMonth(), now.getDate()); 
						break;

					case "tomorrow":
					    dateValue = this.dateAdd("dd", 1, "today");
						break;

					case "yesterday":
						dateValue = this.dateAdd("dd", -1, "today");
						break;

					default:
					    dateValue = new Date(value);
						break;
				}

			} else {
				dateValue = new Date(value);
			}

		} catch (err) {
			console.log(err);			
		}
		if (!dateValue && fallback) {
			try {
				dateValue = this.date(fallback);
			} catch (err) {
				console.log(err);
			}
		}
		return dateValue;
	} // date



	/**
	 * @method parse
	 * Convierte un string de tipo yyyy-mm-dd a un valor tipo fecha o un 
	 * valor tipo yyyy-mm-dd hh:mm:ss a un valor tipo fecha./hora
	 * 
	 * @param  {string} str fecha en formato YYYY-MM-DD [hh:mm:ss]
	 * @return {any}        Valor fecha
	 */
	parse(str:string):any {
	    let y = Number.parseInt(str.substr(0,4));
	    let m = Number.parseInt(str.substr(5,2)) - 1;
	    let d = Number.parseInt(str.substr(8,2));
	    let h = 0, mi = 0 , s = 0;
	    if (str.length >= 16) {
	    	h = Number.parseInt(str.substr(11,2));
	    	mi = Number.parseInt(str.substr(14,2));
	    }
	    if (str.length >= 19) {
	    	s = Number.parseInt(str.substr(17,2));
	    }
	    let D = new Date(y,m,d,h,mi,s);
	    console.log(str, y,m,d,h,mi,s);
	    return (D.getFullYear() == y && D.getMonth() == m && D.getDate() == d) ? D : 'invalid date';	
	} // parse



    /**
     * @method dateParts
     * Devuelve las partes individuales de una fecha dada
     * 
     * @param  {any}     value         Fecha
     * @return {any}                   Objeto con las partes de la fecha
     */
	dateParts(value:any):any {
   		const base = this.date(value);
   		const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
	    const parts = {
	    	yy: base.getFullYear(),
	    	mm: base.getMonth(),
	    	dd: base.getDate(),
	    	hh: base.getHours(),
	    	mi: base.getMinutes(),
	    	ss: base.getSeconds(),
	    	mmm: months[base.getMonth()]
	    }    
	    return parts;			
	} // dateParts



    /**
     * @method dateOnly
     * Devuelve un valor Date sin informacion de hora
     * 
     * @param  {any}    value         Valor tipo Date cuya informacion de hora se quiere eliminar
     * @return {Date}                 Valor DAte sin hora
     */
	dateOnly(value:any):Date {
		const base = this.date(value);
		const parts = this.dateParts(base);
		return new Date(parts.yy, parts.mm, parts.dd); 
	} // dateOnly


	/**
    * @method dateAdd
    * Permite añadir dias a una fecha dada
    *
    * @param  {string}   interval      Intervalo a afectar: dd=days, mm=months, yy=years, hh=hours, mi=minutes, ss=seconds
    * @param  {number}   offset        Cantidad de intervalos a añadir (+/-)
    * @param  {any}      ref           Fecha de referencia. Puede ser un valor date o string.
    * @return {date}                   fecha resultante
    */
	dateAdd(interval:string, offset:number, ref:any):Date {
	    let parts = this.dateParts(ref);
	    parts[interval] += offset;
	    const result = new Date(parts.yy, parts.mm, parts.dd, parts.hh, parts.mi, parts.ss);
	    return result;
	} // dateAdd


	/**
	 * @method dtos
	 * Convierte un valor tipo date a string
	 * 
	 * @param  {any}    value         Valor tipo date a convertir
	 * @param  {string} format        (opcional) formato a aplicar (ej: "dd/mm/yyyy")
	 * @return {string}               Texto representativo de la fecha indicada
	 */
	dtos(value:any, format?:string):string {
    	const base = this.date(value);
		let parts = this.dateParts(base);
    	let result = null;
    	if (format) {
		    result = format;
    		result = result.replace("mmm", parts.mmm);		    
    		result = result.replace("dd", parts.dd.toString().padStart(2,"0"));
    		result = result.replace("mm", (parts.mm + 1).toString().padStart(2,"0"));
    		result = result.replace("yyyy", parts.yy.toString());
    		result = result.replace("yy", parts.yy.toString().substr(2,2));
    		result = result.replace("hh", parts.hh.toString().padStart(2,"0"));
    		result = result.replace("mi", parts.mi.toString().padStart(2,"0"));
    		result = result.replace("ss", parts.ss.toString().padStart(2,"0"));
    	} else {
    		result = base.toDateString();
    		if (parts.hh > 0 || parts.mi > 0 || parts.ss > 0) {
    			let time = base.toLocaleTimeString();
    			const sp = time.lastIndexOf(":");    			
    			result += " " + time.substr(0, sp) + " " + time.substr(time.length - 2, 2);
    		}
    	}
    	return result;
	} // dtos



	isHour(value:string):boolean {
		let parts = value.split(":");
	    if (parts.length == 1) return false;
	    let hh = Number.parseInt(parts[0]);
	    let mm = Number.parseInt(parts[1]);
	    if (hh < 0 || hh > 23) return false;
	    if (mm < 0 || mm > 59) return false;
	    return true; 
	} // isHour


	toISOString(value:any):string {
		if (typeof value == "string") value = this.date(value);
		let local = this.dtos(value,"yyyy-mm-ddThh:mi:ss");
		let offset = value.getTimezoneOffset() / 60;	
		return local + "-" + ((offset < 10) ? "0" : "") + offset + ":00";	
	}

	toUTC(value:any):any {
		console.log("[toUTC] ",typeof value, this.date(value));
		if (typeof value == "string") value = this.date(value);
		return value.setMinutes(value.getMinutes() + value.getTimezoneOffset());
	}

	fromUTC(value:any):any {
		if (typeof value == "string") value = this.date(value);
		return value.setMinutes(value.getMinutes() - value.getTimezoneOffset());
	}	

} // DateUtils

/**
 * NumberUtils (class)
 * Utilidades relacionadas con manejo de numeros
 *
 * @author	Victor Espina S
 * @date 	Nov 30, 2019
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class NumberUtils {

	ntoc = function(value:number, decPlaces?:number, currencySymbol?:string, thouSeparator?:string, decSeparator?:string):string {
	    decPlaces = decPlaces || 2;
	    decSeparator = decSeparator || ".";
	    thouSeparator = thouSeparator || ",";
	    currencySymbol = currencySymbol || "";

	    let n:any = value;
	    let sign:string = (n < 0) ? "-" : "";
	    let i:any = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "";
	    let j:any;
	    j = (j = i.length) > 3 ? j % 3 : 0;

	    return sign + currencySymbol + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
	}

}