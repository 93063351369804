import { ErrorHandler, Injectable } from '@angular/core';
import { AppService } from './services/app.service';
import { LogService } from './services/log.service';

export interface ParsedError {
   message: string;
   status?: number;
   stack?: string;
}


// GlobalErrorHandler
// Manejador de errores global
//
// Basado en: http://www.damirscorner.com/blog/posts/20180330-IonicGlobalErrorHandler.html
//
//
@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

  constructor(
    private app: AppService,
    private log: LogService
  ) {
    super();
  }

  handleError(error: any): void {
    let device = this.app.isDevice;
    let ignore:boolean = false;
    let message:string = "";
    let ex:any = null;
    console.log("[ERROR]", error);

    try {

     if (error.rejection) {
        error = error.rejection;
      }

      ex = this.parseError(error);    
      message = ex.message;

      if (message.toLowerCase().indexOf("http") >= 0 ||
          message.toLowerCase().indexOf("cordova") >= 0) {
        ignore = true;
        // Ignore HTTP errors
      }

      this.log.error(message);


    } catch (err) {
      console.log("[GEH]",err);
      ex = err;
      message = err;
      this.log.error(message);
    }
    
    if (ignore) return;
    alert(message);        

  } // handleError


 parseError(error: any): ParsedError {
   // get best available error message
   let parsedError: ParsedError = {
       message: error.message ? error.message as string : error.toString()
   };

   // include HTTP status code
   if (error.status != null) {
       parsedError.status = error.status;
   }

   // include stack trace
   if (error.stack != null) {
       parsedError.stack = error.stack;
   }

   return parsedError;
 } // parseError

} // GlobalErrorHandler
