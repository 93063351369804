import { Injectable } from '@angular/core';
import { ActionSheetController, ToastController, AlertController, PickerController, LoadingController, PopoverController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { Events } from './events.service';


@Injectable({
  providedIn: 'root'
})
export class UIService {

  loader: any;   // Reference al loading actual
  popover: any;  // Reference al popover actual

  constructor(
  	private actionSheetController: ActionSheetController,
  	private toastController: ToastController,
  	private alertController: AlertController,
  	private pickerController: PickerController,
  	private loadingController: LoadingController,
  	private popoverController: PopoverController,
    private plt: Platform
  ) { 
  	this.loader = null;
  	this.popover = null;
  }


  handleError(err) {
    console.log(err, typeof err);
    if (err.critical) {
      this.presentToast({ message: "Ocurrió un error. Intente de nuevo", color: "danger"});
    } else if (err.statusText) {
      this.presentDangerToast(err.statusText);
    } else if (typeof err === "string") {
      this.presentAlert(err);
    }
  } // handleError


  // presentToast()
  // Muestra un toast en pantalla
  //
  // USO:
  // a) this.ui.presentToast("esto es un mensaje");
  //
  // b) this.ui.presentToast({
  //	   message: "Este es otro mensaje",
  //       positin: "top"
  //    });
  //
  async presentToast(options: any, duration?: number) {
    if (typeof options === "string") options = { message: options, duration: duration || 3000 };
  	options.position = options.position || "bottom";
  	options.duration = options.duration || 3000;
    const toast = await this.toastController.create(options);
    toast.present();
    return toast;
  } // presentToast


  async presentDangerToast(options: any, duration?: number) {
    if (typeof options === "string") options = { message: options, duration: duration || 3000 };
    options.position = options.position || "bottom";
    options.duration = options.duration || 3000;
    options.color = "danger";
    return await this.presentToast(options);
  } // presentToast


  // presentToastWithButton()
  // Presenta un toast con un boton
  //
  presentToastWithButton(options, button) {
  	return new Promise((resolve, reject) => {
  		if (typeof options === "string") options = { message: options };
  		if (typeof button === "string") button = { side: "end", text: button };
  		if (!button.handler) button.handler = () => { resolve(); };
  		options.buttons = [button];
  		this.presentToast(options)
  	});
  } // presentToastWithButton


  // presentActionSheet()
  // Muestra un ActionSheet con las opciones indicadas.  La rutina anexa automaticamente
  // la opcion de Cancelar, si la misma no fue incluida.  La rutina devuelve una promesa
  // que retorna el ID de la opcion seleccionada; si no se indico una propiedad ID en la
  // opcion, se usa el valor de text.
  //
  // USO:
  // a) this.ui
  //        .presentActionSheet(["Opcion 1","Opcion 2"])
  //        .then(id => { console.log("Seleccion:",id); });
  //
  // a) this.ui
  //        .presentActionSheet([{ id: 1, text: "Opcion 1"}, { id: 2, text: "Opcion 2"}])
  //        .then(id => { console.log("Seleccion:",id); });
  //
  presentActionSheet(options) {
  	return new Promise((resolve, reject) => {
		let hasCancel = false;
		if (options.constructor.name === "Array") options = { buttons: options };
		for (let button of options.buttons) {
			if (typeof button === "string") button = { text: button }; 
			button.id = button.id || button.text;
			if (!button.handler) button.handler = () => { resolve(button.id); };  
			if (button.role && button.role == "cancel") hasCancel = true;
		}

		if (!hasCancel)
		  	options.buttons.push({
		  		text: 'Cancelar',
		  		role: 'cancel'
		  	});

	    this.actionSheetController
	        .create(options)
	        .then(actionSheet => { actionSheet.present(); });
	});
  }  // presentActionSheet



  // presentAlert()
  // Muestra un dialogo de alerta
  //
  // USO:
  // a) this.ui.presentAlert(options);
  //
  // b) this.ui.presentAlert("message");
  //
  // c) this.ui.presentAlert("title","message");
  //
  presentAlert(options:any, message?:string):Promise<any> {
    const ctl = this.alertController;
    return new Promise((resolve, reject) => {
      if (typeof options === "string") 
        options = {
          header: (message) ? options : 'Alerta',
          message: message || options,
          buttons: [ { text: 'OK', handler: null} ]
        }          
      if (!options.buttons) options.buttons = [{}];
      if (!options.buttons[0].handler)
        options.buttons[0].handler = () => {
          ctl.dismiss()
             .then(() => { resolve(true); });
          return false;
        }
      ctl.create(options)
         .then((dialog) => dialog.present());
    });
  } // presentAlert



  // presentConfirmDialog()
  // Muestra un dialogo de confirmacion
  //
  // USO:
  //
  // a) const confirmed = await this.ui.presentConfirmDialog("Desea grabar?");
  //
  // a) const confirmed = await this.ui.presentConfirmDialog("Desea grabar?","Si","No");
  //
  // c) this.ui
  //        .presentConfirmDialog({ header: "Salir", message: "Desea salir?"})
  //        .then((result) => {
  //           if (result) {
  //              console.log("OK!");
  //           } else {
  //              console.log("Cancelled");
  //           }
  //        });
  //
  presentConfirmDialog(options, okButtonCaption?, cancelButtonCaption?) {
  	return new Promise((resolve, reject) => {
  		if (typeof options === "string") 
  			options = {
	  			header: "Confirmar",
	  			message: options
	  		};
  		if (!options.buttons) {
  			options.buttons = [  			   
          { text: cancelButtonCaption || "Cancelar", handler: () => { resolve(false); } },
  				{ text: okButtonCaption || "Aceptar", handler: () => { resolve(true); } }
  			]
  		}
  		this.presentAlert(options);
  	});
  } // presentConfirmDialog



  // presentPromptDialog()
  // Presenta un dialogo para pedr un valor
  //
  // USO:
  // a) const dataEntered = await this.ui.presentDialog("Indique su nombre:");
  //
  // b) this.ui
  //        .presentPromptDialog("Indique su nombre:")
  //        .then((data) => { console.log(dataEntered); })
  //        .catch((err)) => { console.log("Dialog cancelled"); });
  //
  // c) this.ui
  //        .presentPromptDialog("Indique su nombre:", defaultValue)
  //        .then((dataEntered) => { console.log(dataEntered); })
  //        .catch((err)) => { console.log("Dialog cancelled"); });
  //
  // d) this.ui
  //        .presentPromptDialog({...})
  //        .then((dataEntered) => { console.log(dataEntered); })
  //        .catch((err)) => { console.log("Dialog cancelled"); });
  //
  presentPromptDialog(options, defaultValue?) {
  	return new Promise((resolve, reject) => {
  	  if (typeof options === "string") 
  	  	options = {
  	  		header: "Atención",
  	  		message: options
  	  	};

      let defaultInput = false;
  	  if (!options.inputs) {
  	  	options.inputs = [
  	  		{ 
  	  			name: 'input1',
  	  			type: 'text'
  	  		}
  	  	];
  	  	defaultInput = true;
  	  	if (defaultValue) 
  	  		options.inputs[0].value = defaultValue;
  	  }

  	  if (!options.buttons) 
  	  	options.buttons = [
	        {
	          text: 'Cancelar',
	          cssClass: 'secondary',
	          handler: () => { resolve(null); }
	        }, 
	        {
	          text: 'Aceptar',
	          handler: (data) => { 
	          	if (defaultInput) {
	          		resolve(data.input1);
	          	} else {
	          		resolve(data);
	          	} 
	          }
	        }
      	];

  	  this.presentAlert(options)
  	      .then(() => {
  	      	const firstInput: any = document.querySelector('ion-alert input');
			firstInput.focus();
			return;
		  });
  	});
  } // presentInputDialog


  
  // presentPickerDialog()
  // Presenta un menu de seleccion simple o multiple
  //
  // USO:
  //
  // a) const fruta = this.ui.presentPickerDialog(["Peras","Manzanas","Fresas"]);
  //
  // b) const diaHora = this.ui.presentPickerDialog(
  //            ["Lun","Mar","Mie","Jue","Vie","Sab","Dom"],
  //            ["Mañana","Vespertino","Nocturno"]);
  //    if (diaHora) {
  //       console.log("Dia", diaHora[0]);
  //       console.log("Turno", diaHora[1]);
  //    }
  //
  presentPickerDialog(values1, values2?):Promise<any> {
  	return new Promise((resolve, reject) => {

  		let options1:any[] = [];
  		let options2:any[] = [];
  		let pickerOptions: any = {};

  		if (values1.constructor.name != "Array") {
  			// Opciones personalizadas
  			pickerOptions = values1
  		} else {
            // Configuracion autmatica
	        pickerOptions = {
		        columns: [],
		    }

	        if (typeof values1[0] === "string") {
	        	for (let value of values1)
	        		options1.push({
	        			text: value,
	        			value: value
	        		});
	        } else {
	        	options1 = values1;
	        }
	        pickerOptions.columns.push({name: "col1",options: options1});

	        if (values2) {
		        if (typeof values2[0] === "string") {
		        	for (let value of values2)
		        		options2.push({
		        			text: value,
		        			value: value
		        		});
		        } else {
		        	options2 = values2;
		        }        	
		        pickerOptions.columns.push({name: "col2",options: options2});
	        }
		}

		if (!pickerOptions.buttons) {
		    pickerOptions.buttons = [
		          {
		            text: 'Cancelar',
		            handler: () => { resolve(null); }
		          },
		          {
		            text: 'Aceptar',
		            handler: (value) => { 
		            	if (pickerOptions.columns.length == 1) {
		            		resolve(value.col1.value); 
		            	} else {
		            		resolve([value.col1.value, value.col2.value]);
		            	}
		            }
		          }
		    ];  					
		}
  		
  		this.pickerController
  		    .create(pickerOptions)
  		    .then((picker) => { picker.present(); } )
  		    .catch(err => { console.log(err); });

  	});
  } // presentPickerDialog



  // presentLoading()
  // Presenta un overlay de espera para indicar que algo se esta procesando y bloquar
  // la interfaz de usuario mientras dura el proceso.
  //
  // USO:
  //
  // await this.ui.presentLoading("Espere....");
  // await doSomeWork();
  // await this.ui.dismissLoading();
  // 
  presentLoading(message: any) {
  	return new Promise((resolve, reject) => {
  		let options: any = {};
  		if (typeof message === "string") {
  			options = { 
  				message: message,
  				translucent: true
  			};
  		} else {
  			options = message;
  		}
  		this.loadingController
  		    .create(options)
  		    .then((loading) => { 
  		    	loading.present();
  		    	this.loader = loading;
  		    	resolve(loading); 
  		    });
  	});
  } // presentLoading


  // dismissLoading()
  // Cierra el ultimo overlay mostrado.  Para mas info, ver presentLoading()
  //
  dismissLoading() {
  	return new Promise((resolve, reject) => {
      if (!this.loader) resolve();
  		this.loader
  		    .onDidDismiss()
  		    .then(() => { resolve(); });
  		this.loader.dismiss();
  		this.loader = null;
  	});
  } // dismissLoading



  presentPopover(ev, options) {  	
  	return new Promise((resolve, reject) => {
  		let html = "<ion-list>";
  		for (let option of options)
  			html += '<ion-item button>' + option + '</ion-item>';
  		html += "</ion-list><ion-button expand='block' onClick='dismissPopover();'>Cerrar</ion-button>";

		function dismissPopover() {
      		if (this.popover) {
        		this.popover
        		    .dismiss()
        		    .then(() => {         		    	
        		    	this.popover = null; 
        		    });
      		}
    	}  		

		customElements.define('ui-service-popover', class ModalContent extends HTMLElement {
		  constructor() {
          	super();
          }
	      connectedCallback() {
	        this.innerHTML = html;
	      }
	    });  		

	    this.popoverController
	        .create({
	        	component: 'ui-service-popover',
	        	event: ev,
	        	translucent: true
	      	})
	      	.then(popover => {
	      		this.popover = popover;
	      		popover.present();
	      		resolve(popover);
	      	})

  	});
  } // presentPopover



  // sleep()
  // Hold process for a given time
  sleep(ms) {
  	return new Promise((resolve, reject) => {
  		setTimeout(() => { resolve(); }, ms);
  	});
  } // sleep
 

} // uiService
