import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from './services/auth.service';
import { AppService } from './services/app.service';
 
@Injectable({
  providedIn: 'root'
})
export class wbAuthGuard implements CanActivate {
 
  constructor(
  	private app: AppService
  ) {}
 
  canActivate(): boolean {
    return (this.app.wbMode);
  }
}
