// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: "https://www.petroautos.com/api/prd/wsapi",
  ssrsUrl: "https://www.petroautos.com/api/prd/wsapi/ssrs",
  petroDB: "PETRO",
  ONESIGNAL_APPID: "a68ed0ca-557c-4539-8856-f554ef79c091",
  ONESIGNAL_SENDERID: "305215051114",
  fac: {
  	wsUrl: "https://ecm.firstatlanticcommerce.com/PGServiceXML",
  	merchantID: "88801182",
  	acquirerID: "464748",
  	currency: "840",
  	password: "iYH3z3y2" 
  },
  webBookingMode: false
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
