import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class RestService  {

  apiUrl: string;
  ssrsUrl: string;
  trace: boolean;

  private _defaultOptions:any;

  constructor(
    private http: HttpClient,
    private log: LogService
  ) { 
    this.apiUrl = environment.apiUrl;
    this.ssrsUrl = environment.ssrsUrl;
    this.trace = false;

    // IMPOTANTE
    // No se debe incluir el encabezado Content-Type: application/json, porque dispara un error
    // de CORS.  Solo los siguientes valores son aceptados:
    // a) application/x-www-form-urlencoded
    // b) multipart/form-data
    // c) text/plain
    //
    this._defaultOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json'
      })
    };

  }

  getSPUrl(spname:string, dbname?:string):string {
    dbname = dbname || "ctapp";
    let baseUrl = this.apiUrl + "/sqlsp/run/{0}/in/" + dbname;
    return baseUrl.replace("{0}",spname);
  }

  getSSRSUrl(catalog:string, report:string):string {
    return this.ssrsUrl + "/run/" + catalog + "/" + report;
  }

  runSP(spname:string,data?:any,dbname?:string):Promise<any> {
    return new Promise((resolve,reject) => {
      let url = this.getSPUrl(spname, dbname);
      data = data || {};
      data.traceRequest = data.traceRequest || false;
      console.log("[runSP]",spname,data);
      this.http
          .put<any[]>(url, data)
          .subscribe(
            (resp:any) => {
              console.log(resp);
              if (resp.result) {
                resp.data = JSON.parse(resp.data);
                resolve(resp.data);
              } else {
                reject({
                  status: 0,
                  statusText: resp.error,
                  critical: false
                });
                this.log.add("[runSP]", resp.error);
              }
            },
            err => {
              err.critical = true;
              this.log.add("[runSP][ERR]", err);
              reject(err);
            } 
          );        
    });
  } // runSP


  runReport(catalog:string, report:string, params:any):Promise<any> {
    return new Promise((resolve, reject) => {
      let url = this.getSSRSUrl(catalog, report);
      params = params || {};
      console.log("[runReport]",catalog,report,params);
      this.http
          .put<any[]>(url, params)
          .subscribe(
            (resp:any) => {
              resolve(resp);
            },
            err => {
              err.critical = true;
              this.log.add("[runSP][ERR]", err);
              reject(err);
            } 
          );   
    }); 
  } // runReport


} // RestService

