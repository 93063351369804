import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Platform } from '@ionic/angular';
import { environment } from '../../environments/environment';
import { TouchID } from '@ionic-native/touch-id/ngx';


export const APP_ACCIONES_VEH = { 
  Ninguna: 0,
  agendarCita: 1,
  mensajeAsesor: 2,
  reagendarCita: 3,
  anularCita: 4,
  eliminarVehiculo: 5,
  Pagar: 6,
  recordatorioPlacas: 7,
  confirmarRegistro: 8,
  simularCobro: 9,
  cerrarOT: 10,
  suTestPagoVISA: 11,
  suTestPagoMCARD: 12
}


@Injectable({
  providedIn: 'root'
})
export class AppService {

  versionInfo:any = {
    version: "1.9.5 beta",
    build: "202210241242",
    fecha: "22 Oct 2022"
  };  

  env:any;
  user:any;
  vehicle:any;
  vehicles:any[];
  branches:any[];
  extras:any[];
  oneSignalPlayerID:string;
  wbMode:boolean = false;    // Indica si estamos en modo WebBooking o no.
  wbPhone:string;            // Numero de telefono indicado
  wbError:string;            // Error ocurrido durante el proceso de WebBooking
  wbErrorMode:number;        // Modo para pantalla de error en WebBooking
  wbOpCode:number;           // 0=Agendar 1=Reagendar
  
  appointment:any;

  events = new Subject();

  // isDevice
  // Determina si la app esta corriendo en un dispositivo o no.  Esto se
  // usa para condicionar algunas rutinas
  //
  get isDevice(): boolean {
    return this.platform.is("hybrid");
  }  


  // testMode
  // Determina si estamos en modo pruebas o no
  //
  get testMode(): boolean {
    return !environment.production;
  }


  // suMode
  // Determina si el usuario actual es un superuser
  //
  get suMode(): boolean { 
    return true;
    //return (this && this.user && this.user.cstEmail.trim() == "vespinas@gmail.com");
  }



  // Indica las capacidades disponibles en el dispositivo actual
  capabilities:any;

  constructor(
    private platform: Platform,
    private touchId: TouchID
  ) { 
    this.env = environment;
  	this.user = null;
  	this.vehicle = null;
    this.vehicles = [];
  	this.branches = null;
    this.extras = [];
    this.capabilities = {
      biometrics: false
    }

  	this.appointment = {
      user: null,
  		vehicle: null,
  		branch: null,
  		custServices: {
  			placaRevisado: false
  		},
  		service: null,
  		repairs: [],
  		extras: [],
  		date: null,
      tipMovCli: 2,
      tiempo: 0,
  		idslot: 0,
      workInfo: null
  	}

    // Si estamos en un dispositivo, verificamos si
    // tiene soporte para identificacion biometrica
    if (this.isDevice)
      this.touchId
          .isAvailable()
          .then(
            res => this.capabilities.biometrics = true,
            err => this.capabilities.biometrics = false
          );

  } // constructor


  /**
   * @method resetAppointment
   * Inicializar el buffer de datos de cita
   * 
   * @param  {any}            id   Id de la cita (reagendar)
   * @param  {any}            data Datos de la cita (reagendar)
   */
  resetAppointment(id?:any, data?:any):void {
  	this.appointment = {
      id: 0,
      user: this.user,
  		vehicle: this.vehicle,
  		branch: null,
  		custServices: {
  			placaRevisado: false
  		},
  		service: null,
  		repairs: [],
  		extras: [],
  		date: null,
      tipMovCli: 2,
      tiempo: 0,
  		idslot: 0,
      slot: "",
      workInfo: null
  	}  	

    if (data) {
      let getRepairInfo = (descr) => {
        const p = descr.indexOf("]");
        return {
          tipo: (p >= 0) ? descr.substring(0, p).replace("[","") : "",
          detalle: (p >= 0) ? descr.substring(p + 1) : descr
        }
      }

      // Recorremos los trabajos adicionales para identificar cuales son MANTTO y cuales son REPARACION
      data.map(i => {
        if (i.descripcion.indexOf("[M") == 0 && i.descripcion.indexOf("X]") > 0) {
          i["tipo"] = "MANTENIMIENTO";
        } else {
          i["tipo"] = "REPARACION";
        }
      });

      let appt = this.appointment;
      appt.id = id;
      appt.branch = this.branches.find((i:any) => i.branchId == data[0].idsucursal);
      appt.date = new Date(data[0].fecha);
      appt.tipMovCli = data[0].tipmovcli;
      appt.idslot = data[0].idslot;
      appt.slot = data[0].slot;
      
      // Aunque el servicio de Placa y Revisado es un adicional, por requisiciones de la gerencia
      // se maneja de forma independeinte
      const placaRevisado = data.find(i => i.tiporen == 2 && i.idadicional == appt.branch.idRevisado);
      appt.custServices.placaRevisado = ((placaRevisado) ? true : false);


      if (data[0].tipotrabajo.trim() == "MANTENIMIENTO") {
        appt.service = {
          nombre: data[0].nomcodsrv,
          opcode: data[0].opcode,
          precio: data[0].precio
        }
      } else {
        appt.service = data.find(i => i.tipo == "MANTENIMIENTO");
        if (appt.service) {
          const w = getRepairInfo(appt.service.descripcion);
          appt.service = {
            nombre: w.detalle,
            opcode: w.tipo,
            precio: appt.service.precio
          }
        }
      }
    
      appt.repairs = data.filter(i => i.tiporen == 3 && i.tipo == "REPARACION") 
                         .map(i => {
                            const w = getRepairInfo(i.descripcion);
                            return { 
                              tipo: w.tipo,
                              notas: w.detalle,
                              eliminar: false
                            }
                         });

      appt.extras = data.filter(i => i.tiporen == 2 && i.idadicional != appt.branch.idRevisado) 
                        .map(i => {
                            return {
                              idinterno: i.idadicional,
                              nombre: i.descripcion,
                              precio: i.precioadi
                            }
                        });                   
    }
  } // resetAppointment



  /**
   * @method getVehActions
   * Devuelve la lista de acciones disponibles para un vehiculo dado
   * 
   * @param  {any}         veh           Datos del vehiculo
   * @return {any[]}                     Lista de acciones disponibles para el vehiculo
   */
  getVehActions(veh?:any):any[] {

    veh = veh || this.vehicle;
    let actions = [];
    const registered = (veh.vehStatus.trim() == "COMPLETADO");        
    const hasAppt = (veh.citasActivas > 0);
    const statusOT = veh.statusot.trim();
    const hasOT = (veh.idot > 0);
    const pagado = (hasOT && veh.pagado);

    if (!registered)
      actions.push({ id: APP_ACCIONES_VEH.confirmarRegistro, text: "Confirmar registro"});

    if (registered && !hasAppt && !hasOT) {
      actions.push({ id: APP_ACCIONES_VEH.agendarCita, text: "Agendar una cita"});

    } else  if (registered && hasAppt && (!hasOT || statusOT == 'ENESPERA')) {
      actions.push({ id: APP_ACCIONES_VEH.reagendarCita, text: "Reagendar cita"});
      actions.push({ id: APP_ACCIONES_VEH.anularCita, text: "Anular cita"});
    }

    if (registered && hasOT) {
      actions.push({ id: APP_ACCIONES_VEH.mensajeAsesor, text: "Enviar mensaje al asesor"});
      if (statusOT == "ENCOBRO" && !pagado)
        actions.push({ id: APP_ACCIONES_VEH.Pagar, text: "Pagar"});
    }

    if (registered) 
      actions.push({ id: APP_ACCIONES_VEH.recordatorioPlacas, text: "Recordatorio de placas"});        
    
    if (registered && this.testMode && hasOT && veh.totalot == 0.00)
      actions.push({ id: APP_ACCIONES_VEH.simularCobro, text: "(PRB) Enviar a cobro "});

    if (registered && this.testMode && hasOT && pagado)
      actions.push({ id: APP_ACCIONES_VEH.cerrarOT, text: "(PRB) Cerrar OT"});

    actions.push({ id: APP_ACCIONES_VEH.eliminarVehiculo, text: "Eliminar este vehiculo", role: "destructive"});

    if (this.suMode && !environment.production) {
      actions.push({id: APP_ACCIONES_VEH.suTestPagoVISA, text: "Probar pago - VISA"});
      actions.push({id: APP_ACCIONES_VEH.suTestPagoMCARD, text: "Probar pago - MASTERCARD"});
    }

    return actions; 
  } // getVehActions



  /**
   * @method isWebBooking
   * DETERMINA SI ESTAMOS EN MODO WEBBOOKING O NO. SE
   * CONSIDERA QUE ES UN WEBBOOKING SI SE DA UNA DE
   * LAS SIGUIENTES CONDICIONES:
   *
   * 1) LA APP ESTA EJECUTANDO EN MODO PROD + NO-HYBRID
   * 2) LA APP ESTA EJECUTANDO EN MODO DEV Y EL FLAG 
   *    webBookingMode ESTA ACTIVO
   * 2) LA RUTA INDICADA ES /BOOK
   */
  isWebBooking(url?:string) {
    let isHybridMode = this.platform.is("hybrid");
    let isBookRoute = false;
    if (url) {
      let route = url.split("?")[0].split("/");
      isBookRoute = (route[1].toLowerCase() == "book");
    }
    return (
            (environment.production && !isHybridMode) || 
            (!environment.production && (environment.webBookingMode || isBookRoute))
           )
  } // isWebBooking


}
