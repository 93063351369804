/*

  Events Service
  This service replace the deprecated Events class on angular.

  To use, just:

  1) Create the events.service using this code
  2) Change your import from this:

     import { Events } from '@ionic/angular';

     to this:

     import { Events } from '{path}/events.service';


  Author: Victor Espina

  DISCLAIMER:
  THIS CODE IS PROVIDED "AS IS". YOU ARE COMPLETELLY RESPONSABLE
  FOR THE CONSEQUENCES OF USE IT.

*/
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class Events {

  private eventsListener:Subject<any> = new Subject(); 
  private clients:any[];

  constructor() { 
    this.clients = [];
    this.eventsListener.subscribe((e) => this._handleEvent(e));
  }


  publish(eventid:string, payload?:any) {
    this.eventsListener.next({
      id: eventid,
      data: payload
    });
  }


  subscribe(eventid:string, callback:any) {
    this.clients.push({
      eventid: eventid,
      callback: callback
    });
  }


  unsubscribe(eventid:string, callback:any) {
    let index = -1;
    for (let i = 0; i < this.clients.length; i++)
      if (this.clients[i].eventid == eventid && this.clients[i].callback == callback)
        index = i;
  }


  private _handleEvent(e) {
    this.clients.map((client) => {
      if (client.eventid == e.id)
        client.callback(e.data);
    });
  }

}


