import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RestService } from './rest.service';
import { AppService } from './app.service';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';
import { DateUtils } from './utils.service';
import { environment } from '../../environments/environment';

const APP_STORE_KEY = "com.petroautos.sucursalvirtual";

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
  	private rest: RestService,
  	private appCtx: AppService,
  	private router: Router,
    private store: Storage,
    private platform: Platform,
    private date: DateUtils
  ) { }

  // getUID()
  // Devuelve un ID numerico unico
  //
  getUID() {
    var d = new Date();
    return d.getMilliseconds();
  } // getUID



  // newGUID()
  // Devuelve un valor GUID
  //
  newGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  } // newGUID



  // dbGet()
  // Devuelve el contenido de una coleccion desde el almacenamiento local. Si se
  // indica el parametro opcional rowid, se devuelve solo el registro especifico
  // indicado o NULL si no se encuentra.
  //
  dbGet(collec:string, rowid?:string):Promise<any> {
    return new Promise(async (resolve, reject) => {
      let dbKey = APP_STORE_KEY + "." + collec;
      let rows = await this.store.get(dbKey);
      rows = rows || [];
      if (rowid) {
        rows = rows.find(i => i.rowid == rowid);
      }
      resolve(rows);
    });
  } // dbGet


  // dbAdd()
  // Anade un nuevo registro a una coleccion dentro del almacenamiento local
  //
  dbAdd(collec:string, data:any):Promise<any> {
    return new Promise(async (resolve, reject) => {
      data.rowid = this.newGUID();
      let dbKey = APP_STORE_KEY + "." + collec;
      let rows = await this.dbGet(collec);
      rows.push(data);
      await this.store.set(dbKey, rows);
      resolve(rows);
    });
  } // dbAdd



  // dbUpdate()
  // Elimina un registro dentro de una coleccion
  //
  dbUpdate(collec:string, rowid:string, data:any):Promise<any> {
    return new Promise(async (resolve, reject) => {
      let rows = await this.dbGet(collec);
      if (rowid) {
        let index = rows.map(i => i.rowid).indexOf(rowid);
        console.log("[dbUpdate]",collec,rows,rowid,index);
        if (index >= 0) {        
          data.rowid = rowid;
          rows[index] = data;
          await this.dbSet(collec, rows);
        }
      }
      resolve(rows);
    });
  } // dbRemove


  // dbRemove()
  // Elimina un registro dentro de una coleccion
  //
  dbRemove(collec:string, rowid:string):Promise<any> {
    return new Promise(async (resolve, reject) => {
      let rows = await this.dbGet(collec);
      let index = rows.map(i => i.rowid).indexOf(rowid);
      console.log("[dbRemove]",rows,index);
      if (index >= 0) {        
        rows = (rows.length > 1) ? rows.splice(index, 1) : [];
        console.log("[dbRemove]",rows);
        await this.dbSet(collec, rows);
      }
      resolve(rows);
    });
  } // dbRemove


  // dbZap()
  // Elimina todo el contenido de la coleccion indicada en el almacenamiento local
  //
  dbZap(collec:string):Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.dbSet(collec, null);
      resolve();
    });
  } // dbTruncate


  // dbSet()
  // Actualiza una coleccion dentro del almacenamiento local
  //
  async dbSet(collec:string, rows:any[]) {
    let dbKey = APP_STORE_KEY + "." + collec;
    await this.store.set(dbKey, rows);
  } // dbSet



  /**
   * @method setPlayerID
   * Asigna un playerid de OneSignal al usuario indicado
   * 
   * @param  {string}    email    Email del usuario
   * @param  {string}    playerid OneSignal PlayerID
   */
  setPlayerID(email:string, playerid:string):Promise<any> {
    return new Promise((resolve, reject) => {
      let data = { cstEmail: email, playerid: playerid };
      this.rest
          .runSP("spUserSetPlayerID", data)
          .then(resp => resolve(resp[0]))
          .catch(err => reject(err));
    });
  }// setPlayerID


  /**
   * @method login
   * Realiza el proceso de verificacion de credenciales del usuario
   * 
   * @param  {string}       email    Email del usuario
   * @param  {string}       password Password del usuario
   * @return {Promise<any>}          
   */
  login(email:string, password:string):Promise<any> {
    return new Promise((resolve, reject) => {
      let data = { email: email, password: password };
      let logged = (user:any) => {
        // Si el usuario no tiene asignado un PlayerID o el mismo es distinto al actual
        // se actualiza 
        if (this.appCtx.oneSignalPlayerID)
          if (!user.osplayerid || user.osPlayerId.trim() != this.appCtx.oneSignalPlayerID)
            this.setPlayerID(email, this.appCtx.oneSignalPlayerID);

        resolve(user);
      } // logged

      this.rest
          .runSP("spUserLogin", data)
          .then(resp => logged(resp[0]))
          .catch(err => reject(err)); 
    });
  } // login



  /**
   * @method logout
   * Cierra la sesion actual del usuario 
   * 
   * @return {Promise<any>} 
   */
  logout():Promise<any> {
    return new Promise((resolve, reject) => {
      resolve();  
    });
  } // logout



  /**
   * @method preRegisterCustomer
   * Inicia el proceso de registro del cliente
   * 
   * @param  {string}            name     Nombres del cliente
   * @param  {string}            lastname Apellidos del cliente
   * @param  {string}            email    Email del cliente
   * @return {Promise<any>}               
   */
  preRegisterCustomer(name:string, lastname:string, email:string):Promise<any> {
    return new Promise((resolve, reject) => {
      let data = { name: name, lastname: lastname, email: email };
      this.rest
          .runSP("spUserPreRegister", data)
          .then(resp => resolve(resp[0]))
          .catch(err => reject(err)); 
    });
  } // preRegisterCustomer


  /**
   * @method validateCustomer
   * Verifica el registro del cliente con el codigo de validacio recibido
   * 
   * @param  {string}         email Email del cliente
   * @param  {number}         code  Codigo de validacion
   * @return {Promise<any>}         
   */
  validateCustomer( email:string, code:number):Promise<any> {
    return new Promise((resolve, reject) => {
      let data = { email: email, validationCode: code };
      this.rest
          .runSP("spUserPreRegisterCode", data)
          .then(resp => resolve(resp[0]))
          .catch(err => reject(err)); 
    });
  } // validateCustomer


  registerCustomer(name:string, lastname:string, email:string, pwd:string):Promise<any> {
    return new Promise((resolve, reject) => {
      let data = { name: name, lastname: lastname, email: email, password: pwd };
      this.rest
          .runSP("spUserRegister", data)
          .then(resp => resolve(resp[0]))
          .catch(err => reject(err)); 
    });
  } // registerCustomer


  registerVehicle(plate:string, vin:string):Promise<any> {
    return new Promise((resolve, reject) => {
      let data = { 
        licencePlate: plate, 
        vin: vin, 
        email: this.appCtx.user.cstEmail 
      };
      this.rest
          .runSP("spCarRegister", data)
          .then(resp => resolve(resp[0]))
          .catch(err => reject(err));
    })
  }


  validateVehicle(code:number):Promise<any> {
    return new Promise((resolve, reject) => {
      let data = { validationCode: code, email: this.appCtx.user.cstEmail };
      this.rest
          .runSP("spCarActivate", data)
          .then(resp => resolve(resp))
          .catch(err => reject(err));
    })
  }


  unregisterVehicle(id:number):Promise<any> {
    return new Promise((resolve, reject) => {
      let data = { id: id, email: this.appCtx.user.cstEmail };
      this.rest
          .runSP("spCarDeactivate", data)
          .then(resp => resolve(resp))
          .catch(err => reject(err));
    })
  }

  getVehicles():Promise<any> {
  	return new Promise((resolve, reject) => {
  		let email = this.appCtx.user.cstEmail.trim();
  		this.rest
  		    .runSP("spCarList", { email: email })
  		    .then(resp => resolve(resp))
  		    .catch(err => reject(err));
  	});
  } // getVehicles


  getTestVehicle():Promise<any> {
    return new Promise((resolve, reject) => {
      this.rest
          .runSP("spQAGetTestVehicle")
          .then(resp => resolve(resp[0]))
          .catch(err => reject(err)); 
    });
  } // getTestVehicle


  getNotifications():Promise<any> {
    return new Promise((resolve, reject) => {
      let email = this.appCtx.user.cstEmail.trim();
      this.rest
          .runSP("spNotificationList", { email: email, newonly: false })
          .then(resp => resolve(this._fixFecha(resp,["ntfDate","ntfReaded","ntfSent"])))
          .catch(err => reject(err));
    });
  } // getVehicles


  markNotificationAsReaded(id):Promise<any> {
    return new Promise((resolve, reject) => {
      this.rest
          .runSP("spNotificationMarkAsRead", { ntfId: id })
          .then(resp => resolve(resp))
          .catch(err => reject(err));
    });    
  }


  markNotificationAsUnreaded(id):Promise<any> {
    return new Promise((resolve, reject) => {
      this.rest
          .runSP("spNotificationMarkAsUnread", { ntfId: id })
          .then(resp => resolve(resp))
          .catch(err => reject(err));
    });    
  }


  sendNotification(type:string, text:string, vin:string) {
    return new Promise((resolve, reject) => {
      const data = {
        ntfType: type,
        ntfDesc: text,
        vehVIN: vin,
        ntfData: ""
      }
      this.rest
          .runSP("spNotificationSend", data)
          .then(resp => resolve(resp))
          .catch(err => reject(err));
    });    
  }


  sendMessage(email:string, vehid:number, msg:string) {
    return new Promise((resolve, reject) => {
      const data = {
        email: email,
        vehId: vehid,
        msg: msg,
        verbose: false
      }
      this.rest
          .runSP("spMessengerSend", data)
          .then(resp => resolve(resp))
          .catch(err => reject(err));
    });    
  }

  getBranches():Promise<any> {
  	return new Promise((resolve, reject) => {
  		this.rest
  		    .runSP("spBranchList")
  		    .then(resp => resolve(resp))
  		    .catch(err => reject(err));
  	});
  }

  getServices(branchId:number, vin:string, kms:number):Promise<any> {
  	return new Promise((resolve, reject) => {
  		let data = {
  			branchId: branchId,
  			svcType: "MANTENIMIENTO",
  			vin: vin,
  			kms: kms
  		}
  		this.rest
  		    .runSP("spApptWorksByBranch", data)
  		    .then(resp => resolve(resp))
  		    .catch(err => reject(err));
  	});
  } // getServices


  getExtras():Promise<any> {
    return new Promise((resolve,reject) => {
      this.rest
          .runSP("spApptListAAS")
          .then(resp => resolve(resp))
          .catch(err => reject(err));
    });
  }


  getSlots(appt):Promise<any> {
    return new Promise((resolve, reject) => {
      let fecha:any = new Date(appt.date);
      console.log(appt.date, fecha, this.date.toUTC(fecha));
      fecha = this.date.toUTC(fecha);
      fecha = this.date.dtos(fecha,"yyyy-mm-dd");
      let data = {
        branchId: appt.branch.branchId,
        svcType: (appt.repairs.length > 0) ? "REPARACION" : "MANTENIMIENTO",
        svcCodeName: (appt.repairs.length > 0) ? "REPARACIONES MENORES" : appt.service.nombre,
        dateFrom: fecha,
        dateUp: fecha
      }
      console.log("[getSlots]",data,appt.date);
      this.rest
          .runSP("spApptAvailList3", data)
          .then(resp => resolve(resp))
          .catch(err => reject(err));
    });
  }

  getSlotInfo(idslot, service) {
    return new Promise((resolve, reject) => {
      let data = {
        slotid: idslot,
        svcCodeName: service.trim()
      }
      this.rest
          .runSP("spApptCodSvcBySlot", data)
          .then(resp => resolve(resp))
          .catch(err => reject(err));
    });
  }


  createAppointment(appt):Promise<any> {
    return new Promise(async (resolve, reject) => {
      let data:any;
      let buff:any[];
      try {
        // Iniciamos la cita
        data = {
          branchId: appt.branch.branchId,
          svcTypeId: appt.workInfo.idtposrv,
          svcCodeId: appt.workInfo.idcodsrv,
          date: new Date(appt.date),
          slotId: appt.idslot,
          cstId: appt.user.cstId,
          time: 0,
          vin: appt.vehicle.vehVin,
          tipmovcli: appt.tipMovCli,
          origen: (this.appCtx.wbMode) ? "WSP" : "APP"  
        }
        data.date = this.date.dtos(data.date,"yyyy-mm-dd");
        let resp:any = await this.rest.runSP("spApptAddNew2", data);
        const apptId = resp[0].apptId;

        // Si hay reparaciones, se incluyen
        buff = [];
        appt.repairs.map(async (repair:any) => {
          data = {
            apptId: apptId,
            apptIncid: "[" + repair.tipo + "] " + repair.notas,
            opcode: ""
          }
          buff.push( this.rest.runSP("spApptAddServiceIncid", data) );
        });

        // Si hay al menos una reparacion, significa que esta cita es para reparacion, por 
        // lo que si se indico un servicio de mantenimiento, el mismo debe ser incluido 
        // como un trabajo mas.
        if (appt.repairs.length > 0 && appt.service) {
          buff.push( this.rest.runSP("spApptAddServiceIncid", {
            apptId: apptId,
            apptIncid: "[" + appt.service.opcode.trim() + "] " + appt.service.nombre,
            opcode: appt.service.opcode
          }));
        }
        await Promise.all(buff);

        // Si hay adicionales, se incluyem
        buff = [];
        appt.extras.map((extra:any) => {
          data = {
            apptId: apptId,
            svcAddlId: extra.idinterno
          }
          buff.push( this.rest.runSP("spApptAddServiceAddl", data) );
        });
        if (appt.custServices.placaRevisado) // Si se marco placa y revisado, se anexa como adicional
          buff.push( this.rest.runSP("spApptAddServiceAddl", {
            apptId: apptId,
            svcAddlId: appt.branch.idRevisado
          }));
        await Promise.all(buff);

        // Finalizamos la cita
        data = {
          apptId: apptId,
          cstId: appt.user.cstId
        }
        resp = await this.rest.runSP("spApptCompleted", data);        
        resolve(resp);


      } catch (err) {
        if (typeof err === "string") err = {
          status: 0,
          statusText: err
        }
        console.log(err);
        reject(err);
      }
    });
  } // createAppointment


  voidAppointment(apptId) {
    return new Promise((resolve, reject) => {
      const data = {
        apptId: apptId,
        cstId: this.appCtx.user.cstId
      }
      this.rest
          .runSP("spApptVoid", data)
          .then(resp => resolve(resp))
          .catch(err => reject(err));
    });
  }  


  getAppointment(apptId) {
    return new Promise((resolve, reject) => {
      this.rest
          .runSP("spApptById", { idcita: apptId })
          .then(resp => resolve(resp))
          .catch(err => reject(err));
    });
  }


  getPrefactura(idot:number, urlonly?:boolean):Promise<any> {
    return new Promise((resolve, reject) => {

      urlonly = urlonly || false;
      if (urlonly) {
        const reportUrl = this.rest.getSSRSUrl("em","pre_factura_ot") + "?idot=" + idot.toString();
        resolve(reportUrl);
        return;
      }

      this.rest
          .runReport("em","pre_factura_ot", { 
            idot: idot
          })
          .then(resp => resolve(resp))
          .catch(err => reject(err));
    });
  } // getPrefactura


  setRRP(vin:string, rrp:Date) {
    return new Promise((resolve, reject) => {
      const data = {
        vin: vin,
        fecha: rrp
      }
      this.rest
          .runSP("spSetRRP", data)
          .then(resp => resolve(resp))
          .catch(err => reject(err));
    });
  }  


  /**
   * @method billOT
   * Enviar una OT al cobro (solo para pruebas)
   * @param  {number}       idot  ID de la OT a pagar
   * @return {Promise<any>}       
   */
  billOT(idot:number):Promise<any> {
    return new Promise((resolve, reject) => {
      let data = { idot: idot, email: this.appCtx.user.cstEmail.trim() };
      this.rest
          .runSP("spVEFacturarOT", data)
          .then(resp => resolve(resp))
          .catch(err => reject(err)); 
    });
  } // billOT

  /**
   * @method releaseVehicle
   * Liberar el vehiculo indicado (mediante el cierre de 
   * cualquier OT asociada)
   * @param  {string}       vin VIN del vehiculo a liberar
   * @return {Promise<any>}       
   */
  releaseVehicle(vin:string):Promise<any> {
    return new Promise((resolve, reject) => {
      let data = { vin: vin };
      this.rest
          .runSP("spQAReleaseVehicle", data)
          .then(resp => resolve(resp))
          .catch(err => reject(err)); 
    });
  } // releaseVehicle


  preparePayment() {
    return {
      numot: "",
      vehId: 0,
      total: 0,
      fecha: new Date(),
      txid: "",
      confid: "",
      cardno: "",
      idpago: 0
    }
  }

  reportPayment(data:any):Promise<any> {
    return new Promise((resolve, reject) => {
      this.rest
          .runSP("spSendPayment", data)
          .then(resp => resolve(resp[0]))
          .catch(err => reject(err));      
    });
  } // reportPayment


  autoReplyMessages():Promise<any> {
    return new Promise((resolve, reject) => {
      this.rest
          .runSP("spMessengerAutoReply")
          .then(resp => resolve(resp))
          .catch(err => reject(err));      
    });
  } // autoReplyMessages


  wbCheckPhone(phone:string):Promise<any> {
    return new Promise((resolve, reject) => {
      this.rest
          .runSP("spATOMCheckCustByPhone", { phoneno: phone }, environment.petroDB)
          .then(resp => resolve(resp))
          .catch(err => reject(err));      
    });
  } // wbCheckPhone

  wbListVehicles(codcli:string):Promise<any> {
    return new Promise((resolve, reject) => {
      this.rest
          .runSP("spATOMListCustVehicles", { codcli: codcli }, environment.petroDB)
          .then(resp => resolve(resp))
          .catch(err => reject(err));      
    });  } // wbListVehicles


  postError(error:any, sender?:string, data?:string):Promise<any> {
    return new Promise((resolve, reject) => {
      if (typeof error == "object") {
        try {
          error = JSON.stringify(error);
        } catch (err) {
          error = "Error al convertir un objeto err: " + err;
        }
      }

      let platform = "pwa";
      if (this.platform.is("android")) {
        platform = "android";
      } else if (this.platform.is("ios")) {
        platform = "ios";
      } 
      
      let info = {
        platform: platform,
        cstEmail: (this.appCtx.user) ? this.appCtx.user.cstEmail : "(not logged)",
        playerID: this.appCtx.oneSignalPlayerID || "",
        sender: sender || "",
        data: data || ""
      }

      let payload = {
        sender: "com.petroautos.sucursalvirtual",
        errormsg: error,
        errorinfo: JSON.stringify(info)
      }
      this.rest
          .runSP("spLogError", payload)
          .then(resp => resolve(resp))
          .catch(err => reject(err));
    })
  }



  // _fixFecha
  // Corrige un valor tipo fecha obtenido desde
  // el servidor
  //
  // USO:
  // rows = this._fixFecha(rows, "fecha");
  // rows = this._fixFecha(rows, ["fecha1","fecha2"]);
  //
  private _fixFecha(res:any[], props:any):any[] {
    if (typeof props === "string") props = [props];
    return res.map(i => {
      props.map(p => i[p] = new Date(i[p]));
      return i;
    });
  } // _fixFecha  
}
